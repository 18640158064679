/* eslint-disable @typescript-eslint/naming-convention */
import { isUserLoggedIn } from "utils";
import { getCandidateFeed } from "utils/apiClientPrivate";
import { getPublicJobFeed } from "utils/apiClients";
import { JOBS_PER_PAGE } from "utils/constants";
import { create } from "zustand";
import departmentData from "cms/department-data";

import { getKeyByValue, SALARY_SCALE } from "constants/filters";
import {
	MIN_EXPERIENCE_DEFAULT_VALUE,
	POSTED_IN_DEFAULT_VALUE,
	VALUE_TO_LABEL_MAPPING,
} from "../constants";

export const MULTI_SELECT_FILTERS = [
	"department_id",
	"work_type",
	"work_mode",
	"work_shift",
];

interface StoreState {
	jobsInStore: [];
	jobsCount: number;
	totalJobsPages: number | null;
	filters: {
		department_id: number[];
		salary: string;
		min_experience: number;
		work_mode: string[];
		work_type: string[];
		work_shift: string[];
		posted_in: number;
		sort_by: string;
		page: number;
		page_size: number;
		education_level: string;
		gender: string;
		english_level: string;
	};
	urlDataObject: {
		slugEntityDetails: {};
		path: null;
		searchQuery: {};
	};
	areJobsLoading: boolean;
	page_size: number;
	citySearchObject: {};
	titleSearchObject: {};
	experienceSearchObject: {};
	isDesktopSearchExpanded: {};
	appliedFiltersCount: number;
	appliedFilters: {
		filterType: string;
		filterValue: string;
		filterLabel: string;
	}[];
	resetFiltersLocalState: boolean;
	defaultLocationCleared: boolean;
	jobFeedSearchInputPrefillData: {};
	// For mixpanel events to check if inputs were manually selected or pre-filled
	manualUserSearchInputs: {
		location: string | null;
		keyword: string | null;
		experience: string | null;
	};
	setFiltersInitialState: (preAppliedFilters) => void;
	setJobs: (jobsData, totalJobCount) => void;
	fetchJobs: () => void;
	applyFilter: (category, value) => void;
	setUrlSearchQuery: (queryObject) => void;
	setSlugEntityDetails: (slugEntity) => void;
	setUrlPath: (slug) => void;
	resetFilters: () => void;
	setCitySearchObject: (obj) => void;
	setTitleSearchObject: (obj) => void;
	setExperienceSearchObject: (obj) => void;
	setIsDesktopSearchExpanded: (value) => void;
	resetSearch: () => void;
	updateAppliedFiltersCount: () => void;
	removeFilter: () => void;
	clearAllFilters: () => void;
	setDefaultLocationCleared: (bool) => void;
	setJobFeedSearchInputPrefillData: (data) => void;
	setManualUserSearchInputs: (inputObject: {
		location?: string;
		keyword?: string;
		experience?: string;
	}) => void;
	applyMobileFilters: (mobileFiltersObject) => void;
	addExperienceInFilters: (value) => void;
}

const filtersInitialState = {
	department_id: [],
	salary: "",
	min_experience: MIN_EXPERIENCE_DEFAULT_VALUE,
	work_mode: [],
	work_type: [],
	work_shift: [],
	posted_in: -1,
	sort_by: "",
	page: 1,
	page_size: JOBS_PER_PAGE,
	education_level: "",
	gender: "",
	english_level: "",
};
const urlDataObjectInitialState = {
	slugEntityDetails: {},
	path: null,
	searchQuery: {},
};

const useJobFeedStore = create<StoreState>((set, get) => ({
	jobsInStore: [],
	jobsCount: 0,
	totalJobsPages: null,
	filters: filtersInitialState,
	urlDataObject: urlDataObjectInitialState,
	page_size: JOBS_PER_PAGE,
	areJobsLoading: false,
	citySearchObject: {},
	titleSearchObject: {},
	experienceSearchObject: {},
	isDesktopSearchExpanded: false,
	appliedFilters: [],
	appliedFiltersCount: 0,
	resetFiltersLocalState: false,
	defaultLocationCleared: false,
	jobFeedSearchInputPrefillData: {},
	manualUserSearchInputs: {
		keyword: null,
		location: null,
		experience: null,
	},
	setFiltersInitialState: (preAppliedFilters) => {
		if (Array.isArray(preAppliedFilters) && preAppliedFilters?.length > 0) {
			preAppliedFilters.forEach((filter) => {
				let filterObj = {
					filterLabel:
						VALUE_TO_LABEL_MAPPING[filter?.name]?.[filter?.selected_value] ||
						filter?.selected_value,
					filterType: filter?.name,
					filterValue: filter?.selected_value,
				};

				if (filter.name === "salary") {
					filterObj = {
						filterLabel:
							VALUE_TO_LABEL_MAPPING[filter?.name]?.[
								filter?.selected_value
							] || filter?.selected_value,
						filterType: filter?.name,
						filterValue: getKeyByValue(
							SALARY_SCALE,
							Number(filter?.selected_value)
						),
					};
				}
				function addFilterIfNotPresent(appliedFilters, newFilter) {
					const isPresent = appliedFilters.some(
						(f) => f.filterType === newFilter.filterType
					);

					if (!isPresent) {
						return [...appliedFilters, filterObj];
					}

					return appliedFilters;
				}

				set((state) => ({
					...state,
					filters: {
						...get().filters,
						[filter?.name]: filter?.selected_value,
					},
					appliedFilters: addFilterIfNotPresent(
						get().appliedFilters,
						filterObj
					),
				}));
			});
		}
		// If experience input in search exists, it will already be present in filtersAppliedState.
		// Following block of code should not run in that case.
		if (
			get().experienceSearchObject?.display_text &&
			!get().appliedFilters.find((filter) => filter.filterType === "min_experience")
		) {
			set((state) => ({
				...state,
				filters: {
					...get().filters,
					min_experience:
						get().experienceSearchObject?.experience_input ||
						MIN_EXPERIENCE_DEFAULT_VALUE,
				},
				appliedFilters: [
					...get().appliedFilters,
					{
						filterLabel:
							get().experienceSearchObject?.display_text ===
							"I am a Fresher"
								? "Fresher"
								: get().experienceSearchObject?.display_text,
						filterType: "min_experience",
						filterValue: get().experienceSearchObject?.experience_input,
					},
				],
			}));
		}
		get().updateAppliedFiltersCount();
	},
	setJobs: (jobsData, totalJobCount) => {
		set((state) => ({
			...state,
			jobsInStore: jobsData || [],
			jobsCount: totalJobCount,
			totalJobsPages: Math.ceil(totalJobCount / JOBS_PER_PAGE),
		}));
	},
	fetchJobs: async () => {
		set((state) => ({ ...state, areJobsLoading: true }));
		const query = {};
		const { filters, urlDataObject } = get();
		const { path, searchQuery } = urlDataObject;
		// Convert search query, filters, and page into a one object
		Object.keys(searchQuery).forEach((key) => {
			query[key] = searchQuery[key];
		});
		Object.keys(filters).forEach((key) => {
			if (Array.isArray(filters[key]) && filters[key].length > 0) {
				query[key] = filters[key].join(",");
			} else if (
				filters[key] &&
				filters[key] !== -1 &&
				filters[key] !== MIN_EXPERIENCE_DEFAULT_VALUE
			) {
				query[key] = filters[key];
			}
		});

		if (path)
			Object.keys(path).forEach((key) => {
				query[key] = path[key];
			});
		// Make an API call with above object for jobs to user-profile-orchestrator
		if (window) {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
		try {
			let response;
			if (!isUserLoggedIn()) {
				response = await getPublicJobFeed(query);
			} else {
				response = await getCandidateFeed(query);
			}
			// Extract jobs and count from response and set in store
			set((state) => ({
				...state,
				jobsCount: response?.data?.count,
				jobsInStore: response?.data?.results?.jobs || [],
				totalJobsPages: Math.ceil(Number(response?.data?.count) / JOBS_PER_PAGE),
				areJobsLoading: false,
			}));
			// // Update URL with final query params
			// const params = new URLSearchParams();
			// // Construct the new URL
			// Object.keys(query).forEach((key) => {
			// 	const value = query[key];
			// 	// Check if the value is valid (e.g., not null, undefined, or empty)
			// 	if (
			// 		value !== null &&
			// 		value !== undefined &&
			// 		value !== "" &&
			// 		!(Array.isArray(value) && value.length === 0) &&
			// 		value !== MIN_EXPERIENCE_DEFAULT_VALUE
			// 	) {
			// 		params.set(key, value);
			// 	}
			// });
			// const newUrl = `${window.location.pathname}?${params.toString()}`;
			// // Replace the current URL without reloading or re-rendering
			// window.history.pushState({}, "", newUrl);
		} catch (e) {
			console.log("error fetching jobs", e);
			set((state) => ({
				...state,
				areJobsLoading: false,
			}));
		}
	},
	applyFilter: (category, value) => {
		const { filters } = get();
		// Check filter type -  Multi select
		if (MULTI_SELECT_FILTERS.includes(category)) {
			// If filter is already selected
			if (filters[category].includes(value)) {
				const indexOfValue = filters[category].findIndex(
					(item) => item === value
				);
				const updatedValuesArray = filters[category].toSpliced(indexOfValue, 1);
				// Update the filter state with new array which does not contain the removed filter
				set((state) => ({
					...state,
					filters: {
						...filters,
						[category]: updatedValuesArray,
						page: 1,
					},
				}));
				// Remove the filter from applied filters array
				// Find index of deselected filter in applied filters array
				const indexOfFilterInAppliedFilters = get().appliedFilters.findIndex(
					(filter) =>
						filter.filterType === category && filter.filterValue === value
				);
				// Remove filter from array and update position of other elements while maintaining order
				const updatedAppliedFiltersArray = get().appliedFilters.toSpliced(
					indexOfFilterInAppliedFilters,
					1
				);
				// Update state
				set((state) => ({
					...state,
					appliedFilters: updatedAppliedFiltersArray,
				}));
			} else {
				// Update filters state with selected filter
				set((state) => ({
					...state,
					filters: {
						...filters,
						[category]: [...filters[category], value],
						page: 1,
					},
				}));
				// Update Applied filters state with selected filter
				// For department filter, we have to look up the name of the department.
				if (category === "department_id" && Array.isArray(departmentData)) {
					const dept = departmentData?.find((d) => d.id === value);
					set((state) => ({
						...state,
						appliedFilters: [
							{
								filterLabel: dept?.name,
								filterType: "department_id",
								filterValue: value,
							},
							...get().appliedFilters,
						],
					}));
				} else {
					set((state) => ({
						...state,
						appliedFilters: [
							{
								filterLabel:
									VALUE_TO_LABEL_MAPPING[category]?.[value] || value,
								filterType: category,
								filterValue: value,
							},
							...get().appliedFilters,
						],
					}));
				}
			}
			// Single select filter
		} else {
			// In filters state, we don't have to check if filter is already selected.
			// We simply overwrite the value if it exists.
			set((state) => ({
				...state,
				filters: {
					...filters,
					page: 1,
					[category]: value,
				},
			}));

			// For applied filters state, if category exists, we remove it from its position.
			// Then we add new selected value on top
			if (get().appliedFilters.find((filter) => filter.filterType === category)) {
				// Remove the filter from applied filters array
				// Find index of deselected filter in applied filters array
				const indexOfFilterInAppliedFilters = get().appliedFilters.findIndex(
					(filter) => filter.filterType === category
				);
				// Remove filter from array and update position of other elements while maintaining order
				let updatedAppliedFiltersArray = get().appliedFilters.toSpliced(
					indexOfFilterInAppliedFilters,
					1
				);
				if (
					value !== "" &&
					value !== MIN_EXPERIENCE_DEFAULT_VALUE &&
					value !== 0 &&
					value !== POSTED_IN_DEFAULT_VALUE
				) {
					updatedAppliedFiltersArray = [
						{
							filterLabel:
								VALUE_TO_LABEL_MAPPING[category]?.[value] || value,
							filterType: category,
							filterValue: value,
						},
						...updatedAppliedFiltersArray,
					];
				}

				// Update state
				set((state) => ({
					...state,
					appliedFilters: updatedAppliedFiltersArray,
				}));
			} else {
				// For default values, we don't want to add them to applied filters array
				if (
					(value === "" && category === "sort_by") ||
					(value === MIN_EXPERIENCE_DEFAULT_VALUE &&
						category === "min_experience") ||
					(value === 0 && category === "salary") ||
					(value === -1 && category === "posted_in")
				) {
					return;
				}
				if (category !== "page")
					set((state) => ({
						...state,
						appliedFilters: [
							{
								filterLabel:
									VALUE_TO_LABEL_MAPPING[category]?.[value] || value,
								filterType: category,
								filterValue: value,
							},
							...get().appliedFilters,
						],
					}));
			}
		}

		// Make an API call with updated filter object
		get().fetchJobs();
		get().updateAppliedFiltersCount();
	},
	applyMobileFilters: (mobileFiltersObject) => {
		set((state) => ({
			...state,
			filters: { ...mobileFiltersObject, page: 1 },
		}));
		get().fetchJobs();
		get().updateAppliedFiltersCount();
	},
	setUrlSearchQuery: (queryObject) => {
		set((state) => ({
			...state,
			urlDataObject: { ...get().urlDataObject, searchQuery: queryObject },
		}));
	},
	setSlugEntityDetails: (slugEntity) => {
		set((state) => ({
			...state,
			urlDataObject: { ...get().urlDataObject, slugEntityDetails: slugEntity },
			citySearchObject:
				slugEntity?.type === "city" ? slugEntity : get().citySearchObject,
		}));
	},
	setUrlPath: (slug) => {
		set((state) => ({
			...state,
			urlDataObject: { ...get().urlDataObject, path: slug },
			filters: { ...get().filters, ...slug },
		}));
	},
	setCitySearchObject: (obj) => {
		set((state) => ({ ...state, citySearchObject: obj }));
	},
	setTitleSearchObject: (obj) => {
		set((state) => ({ ...state, titleSearchObject: obj }));
	},
	setExperienceSearchObject: (obj) => {
		set((state) => ({
			...state,
			experienceSearchObject: obj,
		}));
	},
	setIsDesktopSearchExpanded: (value) => {
		set((state) => ({ ...state, isDesktopSearchExpanded: value }));
	},
	resetSearch: () => {
		set((state) => ({
			...state,
			citySearchObject: {},
			titleSearchObject: {},
			experienceSearchObject: {},
			isDesktopSearchExpanded: false,
			jobFeedSearchInputPrefillData: {},

			filters: filtersInitialState,
			appliedFiltersCount: 0,
			appliedFilters: [],
			resetFiltersLocalState: !get().resetFiltersLocalState,
			urlDataObject: {
				...get().urlDataObject,
				searchQuery: {
					...get().urlDataObject?.searchQuery,
					min_experience: null,
				},
			},
		}));
	},
	updateAppliedFiltersCount: () => {
		const { filters } = get();
		let filtersCount = 0;
		Object.keys(filters).forEach((key) => {
			if (Array.isArray(filters[key]) && filters[key].length > 0) {
				filtersCount += filters[key].length;
			} else if (
				!Array.isArray(filters[key]) &&
				filters[key] &&
				key !== "page" &&
				key !== "page_size" &&
				filters[key] !== MIN_EXPERIENCE_DEFAULT_VALUE &&
				filters[key] !== -1
			) {
				filtersCount += 1;
			}
		});
		if (get().urlDataObject?.path) {
			filtersCount -= 1;
		}
		set((state) => ({ ...state, appliedFiltersCount: filtersCount }));
	},
	removeFilter: () => {},
	clearAllFilters: () => {
		get().resetFilters();
		get().fetchJobs();
	},
	resetFilters: () => {
		set((state) => ({
			...state,
			filters: filtersInitialState,
			appliedFiltersCount: 0,
			appliedFilters: [],
			resetFiltersLocalState: !get().resetFiltersLocalState,
			experienceSearchObject: {},
			urlDataObject: {
				...get().urlDataObject,
				searchQuery: {
					...get().urlDataObject?.searchQuery,
					min_experience: null,
				},
				path: null,
			},
		}));
	},
	setDefaultLocationCleared: (bool) => {
		set((state) => ({
			...state,
			defaultLocationCleared: bool,
		}));
	},
	setJobFeedSearchInputPrefillData: (data) => {
		set((state) => ({
			...state,
			jobFeedSearchInputPrefillData: data,
		}));
	},
	setManualUserSearchInputs: (inputObject) => {
		set((state) => ({
			...state,
			manualUserSearchInputs: { ...get().manualUserSearchInputs, ...inputObject },
		}));
	},
	/* 
		On MOBILE, on search cta click,
		we want to add the experience value to filters sidebar as well.
	 */
	addExperienceInFilters: (value) => {
		set((state) => ({
			...state,
			filters: { ...get().filters, min_experience: value },
		}));
	},
}));
export default useJobFeedStore;
