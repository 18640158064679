/* eslint-disable import/prefer-default-export */
import QueriesKeys from "constants/queryKeys";
import { useQuery } from "react-query";
import { isUserLoggedIn } from "utils";
import { getCandidateProfile } from "utils/apiClientPrivate";

export const useCandidateProfile = (isEnabled = true) =>
	useQuery<any>(
		QueriesKeys.GET_CANDIDATE_PROFILE,
		async () => {
			const { data } = await getCandidateProfile();
			return {
				phoneNumber: data?.phone_number?.replace(
					/^(\+\d{2})(\d)(\d{4})(\d{5})$/,
					"$1-$2$3$4"
				),
				userId: data?.id,
			};
		},
		{
			enabled: isEnabled && !!isUserLoggedIn(),
			retry: false,
		}
	);
