/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */
// fake commut
import React, { ChangeEvent, useEffect, useState } from "react";
import InputField from "components/FormComponents/InputField";
import { useRouter } from "next/router";
import { CircularProgress } from "@material-ui/core";
import { GTAG_EVENTS, GTag } from "utils/Gtag";
import CTAButton from "./components/CTAButton";
import { USER_AUTH } from "./constants";
import { requestOtp } from "./utils/loginApi";
import { getLastSavedNumberDetails, saveNumberDetails } from "./utils/loginUtils";
import useSafePush from "../../hooks/useSafePush";
import {
	trackWebMobileNumberEntered,
	trackWebMobileenterScreenShown,
	trackWebOtpSent,
} from "./utils/analyticsUtils";

const NumberScreen = () => {
	const router = useRouter();
	const { safePush } = useSafePush();

	const [number, setNumber] = useState("");
	const [error, setError] = useState("");
	const [showLoader, setShowLoader] = useState(false);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.trim();
		if (/^\s*-?[0-9]{0,10}\s*$/.test(value) && !value.startsWith("0")) {
			const v = value.replaceAll(/[^0-9]/g, "").trim();
			setNumber(v);
			if (v.length === 10) setError("");
		}
	};

	const handleBlur = () => {};
	const onInputKeyDown = () => {
		if (number?.length < 10) return;
		handleNext();
	};
	const handleNext = async () => {
		// redirection to OTP Screen
		setShowLoader(true);
		const res = await requestOtp(number);
		const details = getLastSavedNumberDetails();
		if (res?.status === 200) {
			trackWebOtpSent(number);
			saveNumberDetails({
				mobileNumber: number,
				otpSentAt: Date.now(),
				verificationAttemptsMade: 0,
				otpSendTries: (details?.otpSendTries || 0) + 1,
			});
			GTag(GTAG_EVENTS.GetOtp);

			safePush(
				`${router.asPath}`.replace(
					`user_auth=${USER_AUTH.NUMBER_SCREEN}`,
					`user_auth=${USER_AUTH.OTP_SCREEN}`
				),
				undefined,
				{
					shallow: true,
				}
			);
		}
	};

	useEffect(() => {
		trackWebMobileenterScreenShown();
	}, []);

	useEffect(() => {
		if (number?.length === 10) {
			trackWebMobileNumberEntered(number);
		}
	}, [number]);

	const TOS = () => (
		<>
			By continuing, you agree to the Apna’s&nbsp;
			<a
				onClick={(e) => e.stopPropagation()}
				href="https://apna.co/user-agreement"
				target="_blank"
				className="text-[#1F8268]"
				rel="noreferrer"
			>
				Terms of service
			</a>
			&nbsp;and&nbsp;
			<a
				onClick={(e) => e.stopPropagation()}
				href="https://apna.co/privacy"
				target="_blank"
				className="text-[#1F8268]"
				rel="noreferrer"
			>
				Privacy Policy
			</a>
		</>
	);

	return (
		<div className="flex flex-col items-center gap-[32px] p-6">
			<h3 className="text-onSurface-primary mb-[0] self-start text-center text-xl font-bold">
				Enter your mobile number
			</h3>
			<div className="flex w-full gap-[12px]">
				<div className="flex items-center justify-center font-semibold">+91</div>
				<div className="w-full border-solid border-[#e5e7eb]">
					<InputField
						error={Boolean(error)}
						errorText={error}
						value={number}
						onChange={handleChange}
						onBlur={handleBlur}
						placeholder="Eg: 9876543210"
						onFocus={() => {
							document.documentElement.scrollTop;
						}}
						inputProps={{
							inputMode: "numeric", // Test this on real device
							maxLength: 10,
							fontSize: 16,
							fontWeight: 500,
						}}
						type="tel"
						onKeyDown={(e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault();
								onInputKeyDown();
							}
						}}
						autoFocus
					/>
				</div>
			</div>

			<footer className="w-full bg-white">
				<p className="mb-[0] w-full pb-[8px] text-xs leading-[16px] text-[#6A6A6A]">
					<TOS />
				</p>
				<div className="border-t py-4">
					<CTAButton
						fullWidth
						onClick={() => handleNext()}
						disabled={number?.length < 10}
					>
						{showLoader ? (
							<CircularProgress
								style={{
									width: "22px",
									height: "22px",
									color: "white",
								}}
							/>
						) : (
							<>Next</>
						)}
					</CTAButton>
				</div>
			</footer>
		</div>
	);
};

export default NumberScreen;
