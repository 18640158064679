/* eslint-disable no-else-return */
import axios from "axios";
import { isUserLoggedIn, getUserId } from "utils";
import config from "../config";
import url from "../config/urls";
import { axiosInstanceWithCandidateAuth } from "./apiClientPrivate";

const getGroups = (id) => {
	const URL = `${config.baseUrl + url.PUBLIC_URL + id}/groups/`;

	return Promise.resolve(axios.get(URL, { timeout: 500 }))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getConnections = async (id) => {
	const URL = `${config.baseUrl + url.PUBLIC_URL + id}/connections/`;
	try {
		const response = await axios.get(URL, { timeout: 500 });
		return response;
	} catch (e) {
		// eslint-disable-next-line no-console
		console.log(e);
	}
	return null;
};

const getJobsForPostsPage = (id) => {
	const URL = `${config.baseUrl + url.PUBLIC_URL_POST + id}/suggested-jobs/`;

	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getSimilarJobs = (id) => {
	const URL = `${config.baseUrl + url.PUBLIC_URL_JOB + id}/suggested-jobs/`;
	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getPaginatedJobs = (paginatedPageUrl) =>
	Promise.resolve(axios.get(paginatedPageUrl))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});

const getDataWithGroupAndCity = (params) => {
	const URL = `${config.baseUrl + url.PUBLIC_URL_JOBS_V2}`;
	return Promise.resolve(
		axios.get(URL, {
			params,
		})
	)
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getGroupMembersData = async (params) => {
	try {
		const baseUrl =
			process.env.ENVIRONMENT === "production"
				? config.infra_base_url_private
				: config.infra_base_url;

		const headers = {};
		if (process.env.ENVIRONMENT !== "production") {
			headers.Authorization = `Token ${process.env.PROFILE_AUTH_TOKEN}`;
		} else {
			headers["X-User-Id"] = "random";
		}
		const URL = `${baseUrl}${url.PUBLIC_URL_GROUPS}`;
		const res = await axios.get(URL, {
			headers,
			params,
		});
		return res;
	} catch (e) {
		// eslint-disable-next-line no-console
		console.log(e, "Data Error");
		return Promise.reject(e);
	}
};

const getSuggestedGroups = (groupId) => {
	const URL = `${config.baseUrl + url.PUBLIC_URL_GROUPS + groupId}/suggested-groups/`;
	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getAllGroups = (cityId) => {
	const URL = `${config.baseUrl + url.PUBLIC_URL_GROUPS}?city_id=${cityId}`;
	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getAllGroupsV2 = () => {
	const URL = config.baseUrl + url.PUBLIC_URL_GROUPS;
	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getAllCategories = (cityId) => {
	const URL = `${config.baseUrl + url.PUBLIC_URL_CATEGORIES}?city_id=${cityId}`;
	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const captureLead = (lead) => {
	const URL = `${config.baseUrl}${url.PUBLIC_WHITE_PAPER_DOWNLOAD}`;

	return fetch(URL, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(lead),
	});
};

const caputreJobLead = (data) => {
	const URL = `${config.baseUrl}${url.PUBLIC_SUBMIT_LEAD}`;
	return fetch(URL, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(data),
	});
};

const captureOtherCitiesLead = (lead) => {
	const URL = `${config.baseUrl}${url.toPUBLIC_OTHER_CITIES_LEAD}`;

	return fetch(URL, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(lead),
	});
};
const getPost = (id) => {
	const URL = `${config.baseUrl + url.PUBLIC_URL_GROUP_POST + id}`;

	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getJobs = (filters = "?") => {
	const URL = `${config.baseUrl}${url.PUBLIC_URL_JOBS_V2}${filters}`;
	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getJobsV2 = (query) =>
	axios
		.get(`${config.baseUrl}${url.PUBLIC_URL_JOBS_V2}`, {
			params: query,
		})

		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});

const getPublicJobFeed = (query) =>
	axios
		.get(`${config.baseUrl}${url.PUBLIC_URL_JOBS_WRAPPER}`, {
			params: query,
		})

		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});

const getFooterLinksV2 = (filters = {}) =>
	axios
		.get(`${config.baseUrl}${url.PUBLIC_URL_FOOTER}`, {
			params: filters,
		})

		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});

const getFooterLinks = (filters = "") => {
	const URL = `${config.baseUrl}${url.PUBLIC_URL_FOOTER}${filters}`;
	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getCities = () => {
	const URL = `${config.baseUrl}${url.PUBLIC_URL_CITIES}`;

	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getCityAreas = () => {
	const URL = `${config.baseUrl}${url.PUBLIC_URL_CITY_AREAS}`;

	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getAreas = (city) => {
	const URL = `${url.CITY_AREA_URL}`;
	return axios.get(URL, { params: { city } });
};

const getJobCategories = () => {
	const URL = `${config.baseUrl}${url.PUBLIC_URL_JOB_CATEGORIES}`;

	return Promise.resolve(axios.get(URL))
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err);
		});
};

const getCategoryFromTypeId = (typeId) => {
	const URL = `${config.baseUrl}${url.PUBLIC_URL_CATEGORY_FROM_TYPEID}`;

	return axios.get(URL, { params: { job_title_id: typeId } });
};

const sendSms = (data) => {
	const URL = `${config.baseUrl}${url.PUBLIC_FREE_JOBS_ALERT}`;

	return fetch(URL, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(data),
	});
};

const requestOtp = (data) => {
	const URL = `${config.baseUrl}${url.REQUEST_OTP}`;

	return axios.post(URL, data);
};

/**
 * @typedef {object} LoginParamObj
 * @property {string} otp
 * @property {string} phone_number_prefixed
 * @property {string} phone_number
 * @property {string} platform
 * @property {string=} request_id
 */

/**
 *
 * @param {LoginParamObj} data
 * @returns {PromiseLike<T>}
 */
const login = (data) => {
	const URL = `${config.baseUrl}${url.LOGIN}`;
	return axios.post(URL, data);
};

const getUserToken = async (encryptedData) => {
	try {
		const URL = `${config.baseUrl}${url.VI_USER_LOGIN}`;
		return await axios.post(URL, encryptedData);
	} catch (error) {
		return Promise.reject(error);
	}
};

const getUserDetails = async (id) => {
	try {
		const URL = config.baseUrl + url.PUBLIC_URL + id;
		return await axios.get(URL);
	} catch (e) {
		return Promise.reject(e);
	}
};

const getSearchResults = (query) => {
	let URL =
		config.baseUrl +
		url.SEARCH_SUGGESTER.replace("{searchKeyword}", encodeURI(query || ""));
	URL += "&source=web";

	if (isUserLoggedIn() && getUserId()) {
		URL += `&user_id=${getUserId()}`;
		return Promise.resolve(axiosInstanceWithCandidateAuth.get(URL))
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err);
			});
	} else {
		return Promise.resolve(axios.get(URL))
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err);
			});
	}
};

const getLocationSearchResults = (query) => {
	let URL =
		config.baseUrl +
		url.LOCATION_SEGGESTER.replace("{searchKeyword}", encodeURI(query || ""));
	URL += "&source=web";

	if (isUserLoggedIn() && getUserId()) {
		URL += `&user_id=${getUserId()}`;
		return Promise.resolve(axiosInstanceWithCandidateAuth.get(URL))
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err);
			});
	} else {
		return Promise.resolve(axios.get(URL))
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err);
			});
	}
};

const getExperienceSearchResults = () => {
	let URL = config.baseUrl + url.EXPERIENCE_SUGGESTER;

	if (isUserLoggedIn() && getUserId()) {
		return Promise.resolve(axiosInstanceWithCandidateAuth.get(URL))
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err);
			});
	} else {
		return Promise.resolve(axios.get(URL))
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err);
			});
	}
};

const getPopularAndRecentTerms = (type) => {
	let URL = config.baseUrl + url.POPULAR_AND_RECENT_TERMS;
	if (type) {
		URL += `&type=${type}`;
	}
	if (isUserLoggedIn() && getUserId()) {
		URL += `&user_id=${getUserId()}`;
		return Promise.resolve(axiosInstanceWithCandidateAuth.get(URL))
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err);
			});
	} else {
		return Promise.resolve(axios.get(URL))
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err);
			});
	}
};

const verifyEmail = async (token) => {
	try {
		return await axios.post(
			`${config.baseUrl}${url.VERIFY_EMAIL}`,
			{ token },
			{
				headers: {
					"X-User-Id": "email-verify",
				},
			}
		);
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	getGroups,
	getConnections,
	getJobsForPostsPage,
	getSimilarJobs,
	getPaginatedJobs,
	getGroupMembersData,
	getSuggestedGroups,
	getDataWithGroupAndCity,
	getAllGroups,
	getAllCategories,
	captureLead,
	caputreJobLead,
	captureOtherCitiesLead,
	getPost,
	getJobs,
	getFooterLinks,
	getCities,
	getCityAreas,
	getJobCategories,
	getAllGroupsV2,
	getCategoryFromTypeId,
	sendSms,
	requestOtp,
	login,
	getUserToken,
	getUserDetails,
	getJobsV2,
	getFooterLinksV2,
	getPublicJobFeed,
	getAreas,
	getSearchResults,
	verifyEmail,
	getLocationSearchResults,
	getPopularAndRecentTerms,
	getExperienceSearchResults,
};
