/* eslint-disable no-nested-ternary */
import { useRouter } from "next/router";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useCandidateConfiguration } from "modules/candidate/profile/hooks/useCandidateConfiguration";
import useSafePush from "../../hooks/useSafePush";
import Modal from "../BaseModal/Modal";
import LoginRefactored from ".";
import useIsDesktop from "../../hooks/useIsDesktop";
import { AUTH_SOURCE, USER_AUTH } from "./constants";
import { getUrlPath } from "../../utils/helpers";
import { axiosInstanceWithCandidateAuth } from "../../utils/apiClientPrivate";
import { TokenUtils } from "./utils/loginUtils";
import { isRHRUser, isUserLoggedIn } from "../../utils";
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "../../utils/constants";

export const LoginRefactoredContext = createContext(undefined);

export const LoginRefactoredContextProvider = ({ children }) => {
	const router = useRouter();
	const { safePush } = useSafePush();
	const isDesktopView = useIsDesktop();
	const { refetch: fetchCandidateConfig } = useCandidateConfiguration();

	const [loginModal, setLoginModal] = useState(false);
	const [shouldStartAuth, setShouldStartAuth] = useState<Boolean>(false);

	const startAuth = useCallback(async (authSource) => {
		/**
		 * Mixpanel
		 */
		let screenType = "";

		const urlPath = getUrlPath();
		if (urlPath === "/") screenType = "main_page";
		if (urlPath.includes("/jobs")) screenType = "job_page";
		if (urlPath.includes("/job/")) screenType = "job_apply_page";
		if (urlPath.includes("/events/")) screenType = "apna_canvas";
		if (urlPath.includes("/immersion-program")) screenType = "svip";

		const analyticsData = {
			screen: screenType,
			source:
				AUTH_SOURCE.JOB_APPLY === authSource
					? "job context login"
					: "non job context login",
		};

		sessionStorage.setItem("auth_analytics_data", JSON.stringify(analyticsData));

		/**
		 * Mixpanel end
		 */

		setShouldStartAuth(true);
	}, []);

	const onCloseModal = ({ updatedPathname, queryObj }) => {
		setLoginModal(false);
		setShouldStartAuth(false);

		if (updatedPathname && queryObj) {
			safePush({
				pathname: updatedPathname,
				query: queryObj,
			});
		} else {
			delete router.query?.user_auth;
			safePush(
				{
					pathname: router.pathname,
					query: router.query,
				},
				undefined,
				{
					shallow: true,
				}
			);
		}
	};

	const handleManualDropOffAuthFlow = () => {
		/**
		 * This function will triggered when users manually drop off from auth flow either by clicking cross button or by tapping on background
		 */

		const onBoardingFlowType = sessionStorage.getItem(
			SESSION_STORAGE_KEYS.ONBOARDING_FLOWTYPE
		);
		/**
		 * Removing Custom CANVAS Onboarding Flow  & Automatic Canvas Challenge Registration Post Onboarding Flow, if users drop off inbetween
		 */
		localStorage.removeItem(LOCAL_STORAGE_KEYS.CANVAS_EVENT_ID_AUTH_FLOW);
		if (onBoardingFlowType && onBoardingFlowType === "apnaCanvas") {
			sessionStorage.removeItem(SESSION_STORAGE_KEYS.ONBOARDING_FLOWTYPE);
		}

		onCloseModal({
			updatedPathname: undefined,
			queryObj: undefined,
		});
	};

	useEffect(() => {
		if (shouldStartAuth && !router.query?.user_auth) {
			safePush(
				`${router.asPath}${
					router.asPath.split("?")[1] ? "&" : "?"
				}user_auth=${encodeURIComponent(USER_AUTH.NUMBER_SCREEN)}`,
				undefined,
				{
					shallow: true,
				}
			);
		}
	}, [shouldStartAuth, router.query, router.asPath]);
	const preventScroll = useCallback((event) => event.preventDefault(), []);

	useEffect(() => {
		/**
		 * We are disabling scroll on behind so that scroll
		 * positively happens on the modal and it doesn't conflict.
		 */

		if (router.query?.user_auth) {
			/**
			 *
			 * Condition to handle AICTE redirections for already logged in users.
			 * https://apna-co.staging.infra.apna.co/?user_auth=phone_number&returnTo=/jobs
			 * https://apna-co.staging.infra.apna.co/?user_auth=phone_number&returnTo=/community
			 * https://apna-co.staging.infra.apna.co/?user_auth=phone_number&returnTo=/resume-builder
			 */
			if (isUserLoggedIn()) {
				const fallback = () => {
					delete router.query?.user_auth;
					delete router.query?.returnTo;
					safePush(
						{
							pathname: router.pathname,
							query: router.query,
						},
						undefined,
						{
							shallow: true,
						}
					);
				};
				const match = router.asPath.match(/returnTo=([^&]+)/);
				const redirectionPath = match && String(decodeURIComponent(match?.[1]));
				if (redirectionPath) {
					/**
					 * Check for the enrichment of the user
					 * Non Enriched => Redirect him to the BlindHR with returnTo param.
					 * Enriched => /jobs redirect them to dynamic link else redirect them to returnTo path
					 */
					axiosInstanceWithCandidateAuth
						.get(
							`/api/userprofile/v1/user/onboarding-completed/${
								isRHRUser() ? "?source=rhr" : ""
							}`
						)
						.then(async (resp) => {
							if (resp.data?.is_profile_complete) {
								if (redirectionPath === "/jobs") {
									window.location.href = `/candidate/jobs?c_id=${TokenUtils.getToken()}`;
								} else {
									window.location.href = redirectionPath;
								}
							} else {
								const candidateConfiguration =
									await fetchCandidateConfig();
								window.location.href = `/${candidateConfiguration?.data?.targetOnboardingRootRoute}?returnTo=${redirectionPath}`;
							}
						})
						.catch(() => {
							fallback();
						});
				} else {
					/**
					 * Fallback for invalid redirectionPath =>  redirect the user to home.
					 */
					fallback();
				}
			} else {
				setLoginModal(true);

				/**
				 * We are disabling scroll on behind so that scroll
				 * positively happens on the modal and it doesn't conflict with background.
				 */

				/**
				 * Not disabling scroll for immersion-program route,
				 * this is causing UX issue for the Sticky Banner as the container height is not properly calcuated post Auth closer
				 * TODO: Redsign the immersion-program route container correctly
				 *
				 */

				if (!router.pathname.includes("events")) {
					document.documentElement.scrollTop;
					document.documentElement.style.overflow = "hidden";
					window.addEventListener("touchmove", preventScroll, {
						passive: false,
					});
				}
			}
		}

		if (router.query?.user_auth) {
			if (!router.pathname.includes("events")) {
				document.documentElement.style.overflow = "scroll";
				window.removeEventListener("touchmove", preventScroll);
			}
		}

		return () => window.removeEventListener("touchmove", preventScroll);
	}, [router.query, router.pathname, router.asPath]);

	return (
		<LoginRefactoredContext.Provider value={startAuth}>
			{children}
			{React.useMemo(
				() => (
					<Modal
						open={loginModal}
						handleClose={() => handleManualDropOffAuthFlow()}
						drawerViewForMobile
						maxWidth={isDesktopView && 400}
					>
						<LoginRefactored
							onCloseModal={onCloseModal}
							handleManualDropOffAuthFlow={handleManualDropOffAuthFlow}
						/>
					</Modal>
				),
				[loginModal, onCloseModal, isDesktopView]
			)}
		</LoginRefactoredContext.Provider>
	);
};
