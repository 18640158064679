/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const AddIconProfileEditor = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} fill="none" {...props}>
		<path
			fill="#8C8594"
			d="M14.25 10.792H10.29v3.958a.794.794 0 0 1-.791.792.794.794 0 0 1-.792-.792v-3.958H4.75A.794.794 0 0 1 3.958 10c0-.435.356-.792.792-.792h3.958V5.25c0-.436.356-.792.792-.792.435 0 .791.356.791.792v3.958h3.959c.435 0 .791.357.791.792a.794.794 0 0 1-.791.792Z"
		/>
	</svg>
);
export default AddIconProfileEditor;
