/* eslint-disable no-nested-ternary */
import { getUserId } from "utils";
import {
	CLAP_LEVELS,
	CLAP_ICONS,
	MONTHS_SHORT_HAND,
	MONTHS_FULL_HAND,
	CLAP_COLORS,
	SESSION_STORAGE_KEYS,
	FAQS_REGEX,
	LOCAL_STORAGE_KEYS,
} from "./constants";

/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-properties */
const CommonUtils = {
	formatNumber: (number) => {
		const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
		// what tier? (determines SI symbol)
		const tier = (Math.log10(number) / 3) | 0;

		// if zero, we don't need a suffix
		if (tier === 0) return number;

		// get suffix and determine scale
		const suffix = SI_SYMBOL[tier];
		const scale = 10 ** (tier * 3);

		// scale the number
		const scaled = number / scale;

		// format number and add suffix
		return scaled.toFixed(2) + suffix;
	},
	generateClapsBadge: (clapCount) => {
		const clap = CLAP_LEVELS.find(({ value }) => clapCount >= value);
		return CLAP_ICONS[clap.level];
	},
	generateClapsColors: (clapCount) => {
		const clap = CLAP_LEVELS.find(({ value }) => clapCount >= value);
		return CLAP_COLORS[clap?.level];
	},
	generateClapsLevel: (clapCount) => {
		const clap = CLAP_LEVELS.find(({ value }) => clapCount >= value);
		return clap.level;
	},
	formatAMPM: (date) => {
		let hours = date.getHours();
		let minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours %= 12;
		hours = hours || 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? `0${minutes}` : minutes;
		const strTime = `${hours}:${minutes} ${ampm}`;
		return strTime;
	},
	getFormattedDate: (currentDate, monthsText, shortMonths, excludeTime) => {
		const date = new Date(currentDate);
		const str = `${date.getDate()} ${
			monthsText
				? shortMonths
					? MONTHS_SHORT_HAND[date.getMonth()]
					: MONTHS_FULL_HAND[date.getMonth()]
				: date.getMonth() + 1
		} ${date.getFullYear()} ${!excludeTime ? CommonUtils.formatAMPM(date) : ""}`;

		return str;
	},
	formatBytes: (bytes, decimals = 2) => {
		if (bytes === 0) return "0 Bytes";

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
	},
	randomIntFromInterval: (min, max) =>
		// min and max included
		Math.floor(Math.random() * (max - min + 1) + min),
	formatNumberWithCommas: (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
	isMobileDevice: (context) =>
		(context.req ? context.req.headers["user-agent"] : navigator.userAgent).match(
			/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
		),
};

export const isMobile = (ctx) => {
	const isMobileView = (
		ctx.req ? ctx.req.headers["user-agent"] : navigator.userAgent
	).match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i);

	return Boolean(isMobileView);
};

/**
 * @returns Date string in DD MMMM YYYY format
 */
export const getDateString = (reverseNumeric = false) => {
	const date = new Date();
	let day = date.getDate();
	let month = date.getMonth();
	const year = date.getFullYear();
	const monthName = MONTHS_FULL_HAND?.[month] || month;

	if (reverseNumeric) {
		if (day < 10) day = `0${day}`;
		if (month + 1 < 10) month = `0${month + 1}`;
		return `${year}-${month}-${day}`;
	}
	return `${day} ${monthName} ${year}`;
};

export const getCityName = (area = "", city) => {
	if (city === "Delhi-NCR") {
		if (area.toLowerCase().match(/gurgaon/)) return "Gurgaon";
		if (area.toLowerCase().match(/noida/)) return "Noida";
		if (area.toLowerCase().match(/ghaziabad/)) return "Ghaziabad";
		if (area.toLowerCase().match(/faridabad/)) return "Faridabad";
		return "Delhi";
	}
	return city;
};

export const areArraysEqual = (arr1, arr2) => {
	if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
		return false;
	}

	return (
		new Set(arr1).size === new Set(arr2).size &&
		new Set([...arr1, ...arr2]).size === new Set(arr1).size
	);
};

export const getSearchEventProps = () => {
	if (typeof window !== "undefined") {
		const searchObj = sessionStorage.getItem(
			SESSION_STORAGE_KEYS.SEARCH_EVENTS_PROPS
		);
		if (searchObj) {
			return JSON.parse(searchObj);
		}
		return {};
	}
	return {};
};

export const setSearchEventProps = (searchEventObj) => {
	if (typeof window !== "undefined" && searchEventObj) {
		sessionStorage.setItem(
			SESSION_STORAGE_KEYS.SEARCH_EVENTS_PROPS,
			JSON.stringify(searchEventObj)
		);
	}
};

export const getPrefilledLocation = () => {
	if (typeof window !== "undefined") {
		const prefilledLocation = sessionStorage.getItem(
			SESSION_STORAGE_KEYS.PREFILLED_LOCATION
		);
		return prefilledLocation || "";
	}
	return "";
};

export const setPrefilledLocation = (prefilledLocation) => {
	if (typeof window !== "undefined" && prefilledLocation) {
		sessionStorage.setItem(
			SESSION_STORAGE_KEYS.PREFILLED_LOCATION,
			prefilledLocation
		);
	}
};

export const formatTimer = (time) => {
	if (time && !Number.isNaN(time)) {
		const minutes = Math.floor(time / 60);
		const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const seconds = Math.floor(time % 60);
		const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
		return `${formatMinutes}:${formatSeconds}`;
	}
	return "00:00";
};

export const formatSeconds = (seconds) => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds - hours * 3600) / 60);
	const secs = seconds - hours * 3600 - minutes * 60;

	let m = minutes.toString();
	let s = secs.toString();

	if (minutes < 10) {
		m = `0${minutes}`;
	}
	if (secs < 10) {
		s = `0${secs}`;
	}
	return `${m}:${s}`;
};
export const checkForValidEmail = (email) => {
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailRegex.test(email);
};

export default CommonUtils;

export const getJobsQNA = (htmlString) => {
	const questionsAndAnswers = [];
	let match;
	while ((match = FAQS_REGEX.exec(htmlString)) !== null) {
		const questionNumber = match[1];
		const question = match[2].trim();
		const answer = match[3].trim();
		questionsAndAnswers.push({ questionNumber, question, answer });
	}
	return questionsAndAnswers;
};

export const getJobListQNA = (htmlString, firstQuestion) => {
	const result = [];
	const h2Regex = /<h2>(.*?)<\/h2>/g;
	let match;
	const lastIndex = 0;

	function getContentBetweenIndices(start, end) {
		return htmlString.slice(start, end).trim();
	}

	const firstH2Index = htmlString.search(h2Regex);
	if (firstH2Index > 0) {
		result.push({
			question: "",
			answer: getContentBetweenIndices(0, firstH2Index),
		});
	}

	while ((match = h2Regex.exec(htmlString)) !== null) {
		const question = match[1].trim();
		const startOfAnswer = h2Regex.lastIndex;
		const nextH2Match = h2Regex.exec(htmlString);
		const endOfAnswer = nextH2Match ? nextH2Match.index : htmlString.length;
		const answer = getContentBetweenIndices(startOfAnswer, endOfAnswer);

		result.push({
			question,
			answer,
		});
		result[0].question = firstQuestion;
		h2Regex.lastIndex = startOfAnswer;
	}
	return result;
};

export const removeHyphenAndUnderscore = (str) => {
	if (str) {
		return str.replaceAll("_", " ").replaceAll("-", " ");
	}
	return "";
};

export const extractJobType = (url) => {
	const regex = /jobs\/([^?]*)/;

	const match = url.match(regex);

	if (match) {
		return match[1];
	}

	return "";
};
export const setJobAsAppliedInLS = (jobId) => {
	if (typeof window !== "undefined" && localStorage) {
		const userId = getUserId();
		const localStorageKey = LOCAL_STORAGE_KEYS.USER_APPLIED_JOBS;
		const userAppliedJobsString = localStorage.getItem(localStorageKey);

		const userAppliedJobs = userAppliedJobsString
			? JSON.parse(userAppliedJobsString)
			: {};

		if (!userAppliedJobs[userId]) {
			userAppliedJobs[userId] = {
				id: userId,
				appliedJobs: {},
			};
		}

		userAppliedJobs[userId].appliedJobs[jobId] = true;
		localStorage.setItem(localStorageKey, JSON.stringify(userAppliedJobs));
	}
};

export const getIsJobAppliedFromLS = (jobId) => {
	if (typeof window === "undefined" || !localStorage) {
		return false;
	}

	const localStorageKey = LOCAL_STORAGE_KEYS.USER_APPLIED_JOBS;
	const userAppliedJobsString = localStorage.getItem(localStorageKey);

	// Return false if nothing is found
	if (!userAppliedJobsString) {
		return false;
	}

	try {
		const userAppliedJobs = JSON.parse(userAppliedJobsString);
		const userId = getUserId();
		const userJobs = userAppliedJobs[userId];

		// Check if the job ID exists and is true in the parsed object
		return userJobs?.appliedJobs?.[jobId] === true;
	} catch (error) {
		// Log error and return false if there was an error parsing the JSON
		console.error("Error parsing JSON from localStorage:", error);
		return false;
	}
};
